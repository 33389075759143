<template>
  <div class="consolAccount">
    <div class="title">
      <h2>Group Setting</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :inline="true" label-width="120px" :model="filter">
              <el-form-item label="Group Name">
                <el-input
                  v-model="filter.groupName"
                  placeholder="account id regex here ..."
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                <el-button type="primary" icon="el-icon-plus" @click="addClick">Create</el-button>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              v-loading="listLoading"
              style="width:100%;"
              height="70vmin"
              stripe
              border
              :data="tableList"
              highlight-current-row
            >
              <el-table-column label="Group Name" prop="groupName" align="center"></el-table-column>
              <el-table-column
                label="Group Owner"
                prop="groupOwner"
                align="center"
              ></el-table-column>
              <el-table-column label="Group Members" prop="membersName"></el-table-column>
              <el-table-column label="Create Time" prop="createTime"></el-table-column>
              <el-table-column label="Action" align="center" min-width="100">
                <template slot-scope="scope">
                  <el-button type="primary" size="small" @click="updateClick(scope.row)">
                    Update Members
                  </el-button>
                  <el-button type="danger" size="small" @click="delGroupClick(scope.row)">
                    Delete
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageSize"
              :currentPage="currentPage"
            ></pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 模块框 -->
    <el-dialog title="Create Group" width="60%" :visible.sync="dialogVisible">
      <el-form label-position="left" :model="groupInfo" label-width="110px">
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Group Name">
              <el-input v-model="groupInfo.groupName" placeholder="group name here ..."></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Group Owner">
              <el-select v-model="groupInfo.groupOwner" style="width:240px;">
                <el-option
                  v-for="item in userOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改角色模块框 -->
    <el-dialog title="Update Members" width="40%" :visible.sync="updateVisible">
      <el-form :model="updateGroupInfo" label-position="left" label-width="100px">
        <el-row>
          <el-col :lg="10" :md="20">
            <el-form-item label="Members" label-width="100px">
              <el-select v-model="updateGroupInfo.members" style="width:240px;" multiple>
                <el-option
                  v-for="item in userOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmUpdateClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delVisible" width="300px" center append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">cancel</el-button>
        <el-button type="primary" @click="delRow">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    addGroupApi,
    deleteGroupApi,
    getGroupListApi,
    updateGroupMemberApi,
  } from 'api/account/groupSetting';
  import { getUserList } from 'api/user';
  import Pagination from '@/components/pagination';
  import { myMixin } from '@/mixins/mixins.js';

  const defaultGroupInfo = {
    groupName: '',
    groupOwner: '',
  };
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    data() {
      return {
        pageSize: 100,
        groupId: '',
        delVisible: false,
        currentPage: 1,
        userOptions: [],
        filter: {
          groupName: null,
        },
        updateGroupInfo: {
          groupId: '',
          members: [],
        },
        total: 0,
        listLoading: false,
        tableList: [],
        groupInfo: Object.assign({}, defaultGroupInfo),
        dialogVisible: false,
        updateVisible: false,
      };
    },
    mounted() {
      this.getUserList();
      this.searchListClick(1);
    },
    methods: {
      async getUserList() {
        try {
          const { code, result } = await getUserList();
          if (code === 200) {
            this.userOptions = result.map((item) => {
              return {
                label: item.uname,
                value: item.id,
              };
            });
          }
        } catch (e) {
          console.error('getUserList error', e);
        }
      },
      searchListClick(page) {
        this.listLoading = true;
        let param = {};
        if (this.filter.groupName != null) {
          param['groupName'] = this.filter.groupName;
        }
        if (page) {
          this.currentPage = 1;
        }
        param['page'] = this.currentPage;
        param['pageSize'] = this.pageSize;
        getGroupListApi(param).then((response) => {
          this.listLoading = false;
          if (response.code == 200) {
            this.tableList = response.result;
            this.total = response.total;
          } else {
            this.$message.error('Error' + response.message);
          }
        });
      },
      addClick() {
        this.groupInfo = Object.assign({}, defaultGroupInfo);
        this.dialogType = 'new';
        this.dialogVisible = true;
      },
      delGroupClick(row) {
        this.groupId = row.groupId;
        this.delVisible = true;
      },
      delRow() {
        deleteGroupApi(this.groupId).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.searchListClick();
            this.delVisible = false;
          } else {
            this.$message.error('Error' + response.message);
          }
        });
      },
      confirmClick() {
        addGroupApi({ ...this.groupInfo }).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.searchListClick();
            this.dialogVisible = false;
          } else {
            this.$message.error('Error' + response.message);
          }
        });
      },
      updateClick(row) {
        this.updateGroupInfo.groupId = row.groupId;
        this.updateGroupInfo.members = row.groupMembers ?? [];
        this.updateVisible = true;
      },
      confirmUpdateClick() {
        updateGroupMemberApi({ ...this.updateGroupInfo }).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update members Success',
              type: 'success',
            });
            this.searchListClick();
            this.updateVisible = false;
          } else {
            this.$message.error('Error' + response.message);
          }
        });
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.searchListClick();
      },
    },
  };
</script>
<style lang="scss" scoped></style>
