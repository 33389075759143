import request from 'utils/request';

const api = {
  GROUP_LIST: '/api/group/list',
  GROUP_ADD: '/api/group/add',
  GROUP_DELETE: '/api/group/delete',
  GROUP_MEMBER_UPDATE: '/api/group/members/update',
};

/**
 * 查询小组列表
 * @param {*} query {page:int,pageSize:int}
 */
export function getGroupListApi(query) {
  return request({
    url: api.GROUP_LIST,
    method: 'get',
    params: query,
  });
}

/**
 * 新增小组
 * @param {*} data {roleName:string,roleDesc:string}
 */
export function addGroupApi(data) {
  return request({
    url: api.GROUP_ADD,
    method: 'post',
    data,
  });
}

/**
 * 删除小组
 * @param {*} groupId 小组id
 */
export function deleteGroupApi(groupId) {
  return request({
    url: api.GROUP_DELETE,
    method: 'delete',
    params: {
      groupId,
    },
  });
}

/**
 * 修改小组成员
 * @param {*} data
 *
 */

export function updateGroupMemberApi(data) {
  return request({
    url: api.GROUP_MEMBER_UPDATE,
    method: 'post',
    data,
  });
}
